<template>
  <div class="apply">
    <center-title title="售后详情"> </center-title>
    <div class="pro-list lighter nr">
      <div class="pro-item row th bg-center">
        <div class="item flex3 pro">商品名称</div>
        <div class="item flex2">购买数量</div>
        <div class="item flex2">支付总价</div>
        <div class="item flex2">申请状态</div>
      </div>
      <div class="pro-item row nr normal" v-if="saleDetail.order_goods">
        <div class="item flex3 pro row">
          <el-image class="pro-img" :src="saleDetail.order_goods.image" fit="contain"></el-image>
          <div class="column" style="overflow: hidden">
            <p class="line1 nr">{{ saleDetail.order_goods.goods_name }}</p>
            <p class="line1 xs">
              {{ saleDetail.order_goods.spec_value }}
            </p>
          </div>
        </div>
        <div class="item flex2 row-center">
          {{ saleDetail.order_goods.goods_num }}
        </div>
        <div class="item flex2 row-center">
          <make-priceformat :price="saleDetail.order_goods.total_pay_price" :subscriptSize="16" :firstSize="16"
            :secondSize="16"></make-priceformat>
        </div>
        <div class="item flex2 row-center">
          {{ saleDetail.status_text }}
        </div>
      </div>
    </div>
    <div class="table nr">
      <div class="table-item row">
        <div class="item-name">售后类型：</div>
        <div class="item-val">{{ saleDetail.refund_type_text }}</div>
      </div>
      <div class="table-item row">
        <div class="item-name">{{ saleDetail.refund_type_text }}原因：</div>
        <div class="item-val">{{ saleDetail.refund_reason }}</div>
      </div>
      <div class="table-item row" v-if="saleDetail.refund_type == 1 || saleDetail.refund_type == 0">
        <div class="item-name">退款金额：</div>
        <div class="item-val">
          <make-priceformat :price="saleDetail.refund_price" :subscriptSize="16" :firstSize="16" :secondSize="16">
          </make-priceformat>
        </div>
      </div>
      <div class="table-item row">
        <div class="item-name">申请时间：</div>
        <div class="item-val">{{ saleDetail.create_time }}</div>
      </div>
      <div class="table-item row">
        <div class="item-name">售后编号：</div>
        <div class="item-val">{{ saleDetail.sn }}</div>
      </div>
      <div class="table-item row" v-if="saleDetail.invoice_no">
        <div class="item-name">物流编号：</div>
        <div class="item-val">{{ saleDetail.invoice_no }} <span class="primary ml10 cursor"
            @click="copy(saleDetail.invoice_no)">复制</span></div>
      </div>
      <div class="table-item row" v-if="saleDetail.refund_image">
        <div class="item-name">售后说明：</div>
        <div class="item-val">
          <el-image style="width: 100px; height: 100px" :src="item" fit="cover" v-for="(item, index) in saleDetail.img"
            :key="index"></el-image>
        </div>
      </div>
      <div class="table-item row" v-if="saleDetail.shop && saleDetail.status == 2">
        <div class="item-name">退货地址：</div>
        <div class="item-val row wrap">
          {{ saleDetail.shop.contact }}, {{ saleDetail.shop.mobile }},
          {{ saleDetail.shop.address }}
          <span class="copy ml20 primary cursor" @click="
            copy(
              saleDetail.shop.contact +
              ',' +
              saleDetail.shop.mobile +
              ',' +
              saleDetail.shop.address
            )
          ">复制</span>
        </div>
      </div>
    </div>
    <div class="row-center button-box" v-if="saleDetail.status == 2">
      <button type="primary" class="white nr" @click="dialogTableVisible = true">
        填写快递单号
      </button>
    </div>
    <div class="row-center button-box"
      v-if="saleDetail.status == 10 && (saleDetail.refund_type == 2 || saleDetail.refund_type == 3 || saleDetail.refund_type == 4)">
      <button type="primary" class="white nr" @click="confirmSale">
        确认收货
      </button>
    </div>
    <el-dialog title="填写快递单号" :visible.sync="dialogTableVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item class="form-input" prop="express_name" label="物流公司">
          <el-input v-model="ruleForm.express_name" placeholder="请输入物流公司"></el-input>
        </el-form-item>
        <el-form-item class="form-input" prop="invoice_no" label="快递单号">
          <el-input v-model="ruleForm.invoice_no" placeholder="请输入快递单号"></el-input>
        </el-form-item>
        <el-form-item class="form-input" prop="express_remark" label="备注说明">
          <el-input v-model="ruleForm.express_remark" placeholder="请输入备注说明" type="textarea"></el-input>
        </el-form-item>
        <!-- <el-form-item class="form-input" prop="password" label="上传凭证">
           <el-upload
          :show-file-list="false"
          class="avatar-uploader"
          :action="`${download}/file/formimage`"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="
            (data) => {
              ruleForm.license_image = data.data.base_uri;
            }
          "
        >
          <img
            v-if="ruleForm.license_image"
            :src="baseURL + ruleForm.license_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" class="nr" @click="submitForm('ruleForm')" style="padding: 10px 50px">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import { vxRule } from "@/utils/validator";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      num: 1,
      saleId: "",
      saleDetail: {},
      dialogTableVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      ruleForm: {
        express_name: "",
        express_remark: "",
        invoice_no: "",
      },
      rules: {
        express_name: vxRule(true, null, "blur", "请输入物流公司"),
        invoice_no: vxRule(true, null, "blur", "请输入快递单号"),
        express_remark: vxRule(true, null, "blur", "请输入备注说明"),
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 确认收获
     */
    confirmSale() {
      // this.receiveAfterSaleApi
      this.$confirm('确定收到货物', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {

        this.$api.receiveAfterSaleApi({
          id: this.saleId
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.getAfterSaleDetail()
        })

      }).catch(() => {

      });
    },
    /**
     * @description 复制
     * @return void
     */
    copy(content) {
      //创建input标签
      var input = document.createElement("input");
      //将input的值设置为需要复制的内容
      input.value = content;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      //成功提示信息
      this.$message.success("复制成功!");
      //移除input标签
      document.body.removeChild(input);
    },
    getAfterSaleDetail() {
      this.$api.getAfterSaleDetailApi({ id: this.saleId }).then((res) => {
        if (res.code == 1) {
          this.saleDetail = res.data;
          this.saleDetail.img = res.data.refund_image.split(",");
        }
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$api
            .addExpressApi({
              ...this.ruleForm,
              id: this.saleId,
            })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  onClose: () => {
                    this.dialogTableVisible = false;
                    this.$router.replace({ path: "/saleslist" });
                  },
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.saleId = this.$route.query.id;
    this.getAfterSaleDetail();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.apply {
  .center-title {
    padding-bottom: 10px;
    border-color: $-color-border;
  }

  .title-right {
    width: 80px;
    height: 26px;
    background-color: $-color-primary;
    border-radius: 3px;
    line-height: 26px;
  }

  .pro-list {
    text-align: center;
    margin-bottom: 15px;

    .pro-item {
      align-items: inherit;

      &.th {
        height: 30px;
        line-height: 30px;
      }

      &:not(.th) {
        .item {
          padding: 30px 25px;
          border-left: $-solid-border;
          border-bottom: $-solid-border;

          &:last-child {
            border-right: $-solid-border;
          }
        }
      }

      .item {
        padding: 0 25px;
        flex-shrink: 0;
        overflow: hidden;

        &.pro {
          text-align: left;

          .pro-img {
            width: 54px;
            height: 41px;
            margin-right: 18px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.table {
  border: $-solid-border;

  .table-item {
    border-bottom: $-solid-border;
  .item-name{
    width: 130px;
  }
    &:last-child {
      border-bottom: none;
    }

    .item-val,
    .item-name {
      padding: 10px 15px;
    }

    .item-val {
      border-left: $-solid-border;
    }
  }
}

.button-box {
  button {
    padding: 10px 20px;
    margin: 20px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
}

::v-deep .avatar-uploader {
  height: 202px;

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img {
      height: 202px;
      width: 202px;
      object-fit: cover;
    }

    &:hover {
      border-color: $-color-primary;
    }
  }

  .avatar-uploader-icon {
    font-size: 40px;
    color: $-color-muted;
    width: 160px;
    height: 160px;
    line-height: 160px;
    text-align: center;
  }

  .avatar {
    width: 160px;
    height: auto;
    display: block;
  }
}
</style>